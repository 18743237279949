import { ConnectorLicense } from "@Service/Connector/Models/ConnectorLicense.model";
import { ConnectorHostFilters } from "@Service/Connector/Models/Filters/ConnectorHostFilters.model";
import { ServiceID } from "@Service/ServiceID";
import { Component } from "types-vue";
import Vue from "vue";

@Component
export default class ConnectorLicenseDetails extends Vue {

  protected serviceId = ServiceID.Connector;
  protected filters = ConnectorHostFilters.create();

  protected viewContract(license: ConnectorLicense) {
    this.$router.push({
      name: "abap-suite-legacy-contract-details",
      params: { id: license.contractId.toString() }
    });
  }

}