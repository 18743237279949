
import { ResourcePermissions } from '@Module/Resources/Models/ResourcePermissions.model';
import { Permission } from '@Module/Auth/Models/Roles/Permissions.model';
import { CustomHubResource } from '@Core/Models/CustomHubResource.model';
import { AccessRole } from '@Module/Auth/Models/Roles/AccessRole.model';
import { SessionToken } from '@Module/Auth/Models/SessionToken.model';
import ResourcesStore from '@Module/Resources/Store/Resources.store';
import { Component, MapAction, MapGetter, Watch } from 'types-vue';
import OrgsStore from '@Module/Orgs/Store/Orgs.store';
import AuthStore from '@Module/Auth/Store/Auth.store';
import { Execution } from '@Core/Models/Execution';
import { ServiceID } from '@Service/ServiceID';
import Vue from 'vue';

const enum SubServiceTab {
  Documents = "documents",
  Archive = "archive",
  Datalakes = "datalakes"
}

const enum ResourceTypeTab {
  Packages = "packages",
  Documentation = "docs",
  Other = "other"
}

const enum CustomResourcesType {
  OnlineDocs = "docs.online"
}

@Component
export default class AbapSuiteDocumentation extends Vue {

  @MapGetter(AuthStore.Mapping)
  protected sessionToken: SessionToken;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(OrgsStore.Mapping)
  protected noOrganization: boolean;

  @MapGetter(ResourcesStore.Mapping)
  protected abapSuiteResourcePermissions: ResourcePermissions;
  
  @MapAction(AuthStore.Mapping)
  protected openAuthDialog: (context?: string) => Promise<boolean>;

  @MapAction(ResourcesStore.Mapping)
  protected fetchResourcePermissions: (service: ServiceID) => Promise<ResourcePermissions>;

  protected serviceId: ServiceID = ServiceID.AbapSuite;
  protected getResPermissions = Execution
    .create(() => this.fetchResourcePermissions(this.serviceId));

  @Watch("sessionToken", { immediate: true })
  protected async onSessionChange(token: SessionToken) {
    if (!!token && !token.hasExpired && !this.noOrganization) {
      this.getResPermissions.run();
    } 
  }

  protected get docResources(): CustomHubResource[] {
    return [{ 
      id: CustomResourcesType.OnlineDocs, 
      label: "CXLink Suite Documentation",
      meta: { url: `${process.env.VUE_APP_SERVICE_URL_ABAP_DOCS}` }
    }]
  }

  protected customResourceSelected(item: CustomHubResource) {
    if (!!item.meta.url) {
      window.open(item.meta.url);
    }
  }

  protected signInForDownloads() {
    const ctx = "Sign into your account to download resources from this service.";
    this.openAuthDialog(ctx);
  }

  protected get resourceTabsDisabled(): boolean {
    return this.getResPermissions.loading || this.downloadsNotAllowed;
  }

  protected get downloadsNotAllowed(): boolean {
    return !!this.abapSuiteResourcePermissions && !this.abapSuiteResourcePermissions.canDownload;
  }

  protected openIssueManager() {
    window.open(process.env.VUE_APP_SERVICE_URL_ISSUES);
  }

  protected get permissions() {
    return {
      CanPurchaseContracts: this.accessRole.can(this.serviceId, Permission.PurchaseContract),
      CanManageIssues: this.accessRole.can(this.serviceId, Permission.ManageIssues),
      CanDownloadPackages: this.accessRole.can(this.serviceId, Permission.DownloadPackages),
      CanDownloadOtherPackages: this.accessRole.can(this.serviceId, Permission.DownloadOtherResources)
    }
  }

}