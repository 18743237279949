import { OrgInvitationNotificationLink } from "@Module/Notifications/Models/OrgInvitationNotification.model";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { ContractPurchase } from "../Models/ContractPurchase.model";
import LicensingStore from "@Module/Licensing/Store/Licensing.store";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import { Component, MapAction, MapGetter, Vue } from "types-vue";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import MenuStore from "@Core/Store/Menu/Menu.store";

@Component
export default class AuthView extends Vue {

  @MapGetter(AppSettingsStore.Mapping)
  protected isPlainUi: boolean;

  @MapGetter(NotificationsInboxStore.Mapping)
  protected orgInvitationLink: OrgInvitationNotificationLink;

  @MapGetter(LicensingStore.Mapping)
  protected contractPurchase: ContractPurchase;


  protected get documentationUrl(): string{
    return `${process.env.VUE_APP_SERVICE_URL_DOCS}`
  }


  @MapAction(MenuStore.Mapping)
  protected toggleFloatingMenuVisibility: () => Promise<void>;

  @MapGetter(OrgsStore.Mapping)
  protected noOrganization: boolean;

  protected get viewClass() {
    return {
      "is-plain": this.isPlainUi
    }
  }

  protected onSignInSuccess() {
    if (this.noOrganization) {
      this.$router.replace(ConsoleRoute.OrgConfigPath);
    } else {
      this.$router.replace(ConsoleRoute.HubPath);
    }
  }

  protected get contentStyle() {
    let marginTop = 3;
    if (!!this.orgInvitationLink) {
      marginTop -= 1;
    }
    if (!!this.contractPurchase) {
      marginTop -= 1;
    }
    return {
      "margin-top": `${marginTop}vh`
    }
  }

}