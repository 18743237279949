import { ILicenseDto, License } from "@Module/Licensing/Models/License.model";
import { IHostDto } from "@Module/Licensing/Models/Host.model";
import { ConnectorHost } from "./ConnectorHost.model";
import { ServiceID } from "@Service/ServiceID";

export class ConnectorLicense extends License<ConnectorHost> {

  protected constructor(dto: ILicenseDto) {
    super(dto, ServiceID.Connector);
  }

  protected parseHosts(dtos: IHostDto[]): ConnectorHost[] {
    return dtos.map(ConnectorHost.fromDto);
  }

  public static fromDto(dto: ILicenseDto): ConnectorLicense {
    return new ConnectorLicense(dto);
  }

}