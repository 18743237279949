import { Contract } from "@Module/Licensing/Models/Contract.model";
import { DatePickerOptions } from "element-ui/types/date-picker";
import UsersStore from "@Module/Users/Store/Users.store";
import { User } from "@Module/Users/Models/User.model";
import Dialog from "@Core/Components/Dialog/Dialog";
import { Execution } from "@Core/Models/Execution";
import { Component, MapAction, MapGetter } from "types-vue";
import DayJS from "dayjs";
import Vue from "vue";
import { Notify } from "@Core/Utils/Notify";
import LicensingStore, { UpdateContractPayload } from "@Module/Licensing/Store/Licensing.store";
import { Prop, Watch } from "vue-property-decorator";
import { ServiceID } from "@Service/ServiceID";
import { ContractOrigin } from "@Module/Licensing/Models/ContractOrigin";

@Component
export default class AdminEditContractDialog extends Vue {

  @Prop({ type: String, required: true })
  protected serviceId: ServiceID;

  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;

  @MapAction(LicensingStore.Mapping)
  private updateContract: (payload: UpdateContractPayload) => Promise<void>;

  protected contract: Contract = null;
  protected expiration: Date = null;
  protected poc: boolean = false;
  protected origin: string = null;
  protected extendedContractId: number = null;
  protected originsLinke = ContractOrigin.OnlyLinke;
  protected editContract = Execution
    .create((contract: Contract, newExpDate: Date, poc: boolean, extendedContractId: number, origin: string, product: ServiceID) => this.updateContract({
      service: this.serviceId,
      data: {
        id: contract.id,
        dto: { expirationDate: newExpDate.toISOString(), isPoc: poc, extended_contract_id: extendedContractId, origin: origin, product: product },
      }
    }));

  public open(contract: Contract): Promise<boolean> {
    const dialog = this.$refs.dialog as Dialog;
    if (!dialog) { 
      return Promise.resolve(false); 
    }
    this.contract = contract;
    this.expiration = contract.expiration.locale("es").toDate();
    this.origin = contract.origin;
    this.extendedContractId = contract.extendedContractId;
    this.poc = contract.is_poc;
    return dialog.open();
  }

  protected onClosed() {
    this.contract = null;
    this.editContract.reset();
  }

  @Watch("origin")
  protected onOriginChanged() {
    if (this.origin !== ContractOrigin.Extended){
      this.extendedContractId = null;
    }
  }

  protected async edit() {
    await this.editContract.run(this.contract, this.expiration, this.poc, this.extendedContractId, this.origin, this.serviceId);
    if (!this.editContract.error) {
      Notify.Success({
        title: "Contract updated",
        message: `Contract successfully updated.`
      });
      const dialog = this.$refs.dialog as Dialog;
      dialog.success();
    }
  }

  protected get canEditContract(): boolean {
    return !!this.expiration 
      && !!this.contract 
      && (!this.contract.expiration.isSame(this.expiration) || 
      this.contract.is_poc !== this.poc ||
      this.contract.extendedContractId !== this.extendedContractId ||
      this.contract.origin !== this.origin);
  }

  protected get datePickerOptions(): DatePickerOptions {
    return {
      firstDayOfWeek: 1,
      disabledDate: (time) => time.getTime() < Date.now(),
      shortcuts: [{
        text: 'Extend 1 Week',
        onClick: (picker) => picker.$emit("pick", this.contract.expiration.add(1, "weeks").toDate())
      }, {
        text: 'Extend 15 Days',
        onClick: (picker) => picker.$emit("pick", this.contract.expiration.add(15, "days").toDate())
      }, {
        text: 'Extend 1 Month',
        onClick: (picker) => picker.$emit("pick", this.contract.expiration.add(1, "month").toDate())
      }, {
        text: 'Extend 3 Months',
        onClick: (picker) => picker.$emit("pick", this.contract.expiration.add(3, "months").toDate())
      }, {
        text: 'Extend 6 Months',
        onClick: (picker) => picker.$emit("pick", this.contract.expiration.add(6, "months").toDate())
      }, {
        text: 'Extend 9 Months',
        onClick: (picker) => picker.$emit("pick", this.contract.expiration.add(9, "months").toDate())
      }, {
        text: 'Extend 1 Year',
        onClick: (picker) => picker.$emit("pick", this.contract.expiration.add(1, "year").toDate())
      }]
    }
  }

}