var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('licensing.licenses-view',{attrs:{"filters":_vm.filters,"service-id":_vm.serviceId},on:{"select":_vm.viewDetails,"select-contract":_vm.viewContract},scopedSlots:_vm._u([{key:"host-filters",fn:function(ref){
var data = ref.data;
var size = ref.size;
var disabled = ref.disabled;
return _c('abap-suite.host-filters-panel-extension',{attrs:{"filters":_vm.filters.hosts,"data":data,"size":size,"disabled":disabled}})}},{key:"expand",fn:function(ref){
var license = ref.license;
var exchangeStatus = ref.exchangeStatus;
return _c('licensing.license-summary',{attrs:{"license":license,"expand":""}},[_c('div',{staticClass:"flex content-space-between"},[_c('div',{staticClass:"flex-inline space-x-8"},[_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-more"},on:{"click":function($event){return _vm.viewDetails(license)}}},[_c('span',[_vm._v("View license details")])]),(_vm.permissions.CanListContracts
            && license.isOwnedByOrg(_vm.currentOrg))?_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.viewContract(license)}}},[_c('ion-icon',{staticClass:"mr-8",attrs:{"name":"receipt-outline"}}),_c('span',[_vm._v("View license contract")])],1):_vm._e()],1),(_vm.permissions.CanRegisterHosts && (!exchangeStatus.isShared || exchangeStatus.isSharedToCurrentOrg))?_c('el-button',{attrs:{"size":"small","type":"default","disabled":!license.hasAvailability},on:{"click":function($event){return _vm.$refs.hostRegistrationDialog.open(license)}}},[_c('ion-icon',{staticClass:"mr-6",attrs:{"name":"link-outline"}}),_c('span',[_vm._v("Register a host")])],1):_vm._e()],1)])}}])},[(_vm.permissions.CanRegisterHosts)?_c('licensing.host-registration-dialog',{ref:"hostRegistrationDialog",attrs:{"service-id":_vm.serviceId}}):_vm._e(),_c('core.query-param',{attrs:{"name":"prod","type":"string-array"},model:{value:(_vm.filters.hosts.production),callback:function ($$v) {_vm.$set(_vm.filters.hosts, "production", $$v)},expression:"filters.hosts.production"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }