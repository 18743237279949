import { LicenseFilters } from "@Module/Licensing/Models/LicenseFilters.model";
import { ConnectorHostFilters } from "./ConnectorHostFilters.model";

export class ConnectorLicenseFilters extends LicenseFilters {

  public hosts: ConnectorHostFilters;

  protected initialize() {
    super.initialize();
    this.hosts = ConnectorHostFilters.create();
  }

  public static create(): ConnectorLicenseFilters {
    return new ConnectorLicenseFilters();
  }

}