import { ServiceID } from "@Service/ServiceID";
import { Console } from "vue-router";

import ServiceViewport from "@Core/Components/ServiceViewport/ServiceViewport.vue";
import Viewport from "@Core/Components/Viewport/Viewport.vue";

import ConnectorHub from './Views/Hub/ConnectorHub.vue';
import ConnectorContracts from "./Views/Contracts/ConnectorContracts.vue";
import ConnectorLicenses from "./Views/Licenses/ConnectorLicenses.vue";
import ConnectorContractDetails from './Views/ContractDetails/ConnectorContractDetails.vue';
import ConnectorLicenseDetails from './Views/LicenseDetails/ConnectorLicenseDetails.vue';
import ConnectorPackages from "./Views/Packages/ConnectorPackages.vue";
import ConnectorDocumentation from "./Views/Documentation/ConnectorDocumentation.vue";

const routes: Console.RouteConfig[] = [
  {
    path: "/abap-suite-legacy",
    name: "abap-suite-legacy",
    component: ServiceViewport,
    redirect: { name: "abap-suite-legacy-hub" },
    meta: {
      authRequired: true,
      appInfo: {
        legacy: { redirect: "/abap-suite" },
        version: "2.1.0",
        serviceId: ServiceID.Connector,
        summary: "SAP Certified add-on that leverages the power of the ABAP stack to provide a seamless integration with the hyperscalers ecosystem.",        purchaseMethods: [
          { label: "AWS Marketplace", url: process.env.VUE_APP_MARKETPLACE_URL_CONNECTOR },
          { label: "Contact us by email", url: `mailto:${process.env.VUE_APP_CONTACT_EMAIL}` }
        ]
      },
      menu: {
        label: ServiceID.nameOf(ServiceID.Connector),
        groupId: "service",
        icon: {
          type: "custom",
          name: "icons/abap-suite.svg"
        }
      }
    },
    children: [
      {
        path: "hub",
        name: "abap-suite-legacy-hub",
        component: ConnectorHub,
        meta: {
          authRequired: true,
          menu: {
            label: "Service Hub",
            icon: {
              type: "ion-icon",
              name: "bookmark"
            }
          }
        }
      },
      {
        path: "licensing",
        name: "abap-suite-legacy-licensing",
        component: Viewport,
        redirect: { name: "abap-suite-legacy-contracts", append: false},
        meta: {
          authRequired: true,
          breadcrumb: {
            hidden: true
          },
          menu: {
            label: "Licensing",
            icon: {
              type: "ion-icon",
              name: "id-card"
            }
          }
        },
        children: [
          {
            path: "/abap-suite-legacy/contracts",
            name: "abap-suite-legacy-contracts",
            component: ConnectorContracts,
            meta: {
              authRequired: true,
              permissions: "nimbus:ListContracts",
              menu: {
                label: "Contracts",
                icon: {
                  type: "ion-icon",
                  name: "receipt-outline"
                }
              }
            }
          },
          {
            path: "/abap-suite-legacy/contracts/:id",
            name: "abap-suite-legacy-contract-details",
            component: ConnectorContractDetails,
            meta: {
              authRequired: true,
              permissions: "nimbus:ListContracts",
              breadcrumb: {
                prependPaths: ["/abap-suite-legacy/contracts"]
              },
              menu: {
                hidden: true,
                selectionPath: "/abap-suite-legacy/contracts",
                label: "Contract Details",
                icon: {
                  type: "ion-icon",
                  name: "receipt-outline"
                }
              }
            }
          },
          {
            path: "/abap-suite-legacy/licenses",
            name: "abap-suite-legacy-licenses",
            component: ConnectorLicenses,
            meta: {
              authRequired: true,
              permissions: "nimbus:ListLicenses",
              menu: {
                label: "Licenses",
                icon: {
                  type: "ion-icon",
                  name: "reader-outline"
                }
              }
            }
          },
          {
            path: "/abap-suite-legacy/licenses/:id",
            name: "abap-suite-legacy-license-details",
            component: ConnectorLicenseDetails,
            meta: {
              authRequired: true,
              permissions: "nimbus:ListLicenses",
              breadcrumb: {
                prependPaths: ["/abap-suite-legacy/licenses"]
              },
              menu: {
                hidden: true,
                selectionPath: "/abap-suite-legacy/licenses",
                label: "License Details",
                icon: {
                  type: "ion-icon",
                  name: "reader-outline"
                }
              }
            }
          }
        ]
      },
      {
        path: "resources",
        name: "abap-suite-legacy-resources",
        component: Viewport,
        redirect: { name: "abap-suite-legacy-packages", append: false},
        meta: {
          authRequired: true,
          breadcrumb: {
            hidden: true
          },
          menu: {
            label: "Resources",
            icon: {
              type: "ion-icon",
              name: "library-outline"
            }
          }
        },
        children: [
          {
            path: "/abap-suite-legacy/downloads",
            name: "abap-suite-legacy-downloads",
            component: ConnectorPackages,
            meta: {
              authRequired: true,
              permissions: "nimbus:DownloadPackages",
              menu: {
                label: "Downloads",
                icon: {
                  type: "ion-icon",
                  name: "archive-outline"
                }
              }
            }
          },
        ]
      }
    ]
  }
];

export default routes
