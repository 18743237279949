import { ConnectorHostFilters } from "@Service/Connector/Models/Filters/ConnectorHostFilters.model";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { ConnectorHost } from "@Service/Connector/Models/ConnectorHost.model";
import { Component, Prop } from "types-vue";
import Vue from "vue";

@Component
export default class ConnectorHostFiltersPanelExtension extends Vue {

  @Prop({ type: Object, required: true })
  protected filters: ConnectorHostFilters;

  @Prop({ type: Boolean, default: false })
  protected disabled: boolean;

  @Prop({ type: String, default: null })
  protected size: UISize;

  @Prop({ type: Array, default: null })
  protected data: ConnectorHost[];

}