import { Organization } from "@Module/Orgs/Models/Organization.model";
import { LicensingStatusPhase } from "./LicensingStatus.model";
import { HostFilters } from "./HostFilters.model";
import { Dimension } from "./Dimension.model";

export class LicenseFilters {
  public search: string;
  public status: LicensingStatusPhase[];
  public dimensions: Dimension[];
  public withAvailability: boolean;
  public onlyWithHosts: boolean;
  public tenants: Organization[];
  public owners: Organization[];
  public hosts: HostFilters;
  
  public static create(): LicenseFilters {
    return new LicenseFilters();
  }

  protected constructor() { 
    this.initialize();
  }

  public reset() { 
    this.initialize();
  }

  protected initialize() {
    this.search = null;
    this.status = [];
    this.dimensions = [];
    this.withAvailability = false;
    this.onlyWithHosts = false;
    this.hosts = HostFilters.create();
    this.tenants = [];
    this.owners = [];
  }

  public get isFiltering(): boolean {
    return this.search !== null 
        || this.onlyWithHosts
        || this.tenants.length > 0
        || this.owners.length > 0
        || this.status.length > 0 
        || this.dimensions.length > 0
        || this.withAvailability === true
        || this.hosts.isFiltering;
  }

}