import { ResourcePermissions } from '@Module/Resources/Models/ResourcePermissions.model';
import { Permission } from '@Module/Auth/Models/Roles/Permissions.model';
import { CustomHubResource } from '@Core/Models/CustomHubResource.model';
import { AccessRole } from '@Module/Auth/Models/Roles/AccessRole.model';
import { SessionToken } from '@Module/Auth/Models/SessionToken.model';
import ResourcesStore from '@Module/Resources/Store/Resources.store';
import { Component, MapAction, MapGetter, Watch } from 'types-vue';
import OrgsStore from '@Module/Orgs/Store/Orgs.store';
import AuthStore from '@Module/Auth/Store/Auth.store';
import { Execution } from '@Core/Models/Execution';
import { ServiceID } from '@Service/ServiceID';
import Vue from 'vue';
import { ProductVersion } from '@Module/Resources/Models/ProductVersion.model';
import ResourcesApi from '@Module/Resources/API/Resources.api';
import { Platform } from '@Module/Resources/Models/Platform.model';


const enum CustomResourcesType {
  OnlineDocs = "docs.online"
}   

@Component
export default class ConnectorPackages extends Vue {

  @MapGetter(AuthStore.Mapping)
  protected sessionToken: SessionToken;


  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(OrgsStore.Mapping)
  protected noOrganization: boolean;

  @MapGetter(ResourcesStore.Mapping)
  protected connectorResourcePermissions: ResourcePermissions;
  
  @MapAction(AuthStore.Mapping)
  protected openAuthDialog: (context?: string) => Promise<boolean>;

  @MapAction(ResourcesStore.Mapping)
  protected fetchResourcePermissions: (service: ServiceID) => Promise<ResourcePermissions>;

  @MapAction(ResourcesStore.Mapping)
  protected openManageResourcesDialog: () => Promise<boolean>;

  @MapAction(ResourcesStore.Mapping)
  protected closeManageResourcesDialog: () => Promise<void>;
  protected serviceId: ServiceID = ServiceID.AbapSuite;

  protected getResPermissions = Execution
    .create(() => this.fetchResourcePermissions(ServiceID.AbapSuite));

  @Watch("sessionToken", { immediate: true })
  protected async onSessionChange(token: SessionToken) {
    if (!!token && !token.hasExpired && !this.noOrganization) {
      this.getResPermissions.run();
    } else {
      this.getResPermissions.reset();
    }
  }


  protected get docResources(): CustomHubResource[] {
    return [{ 
      id: CustomResourcesType.OnlineDocs, 
      label: "Data Archiving (Online)",
      meta: { url: `${process.env.VUE_APP_SERVICE_URL_ABAP_DOCS}/cxlink-archive` }
    },
    { 
      id: CustomResourcesType.OnlineDocs, 
      label: "AWS Data Lakes (Online)",
      meta: { url: `${process.env.VUE_APP_SERVICE_URL_ABAP_DOCS}/cxlink-datalakes` }
    },
    { 
      id: CustomResourcesType.OnlineDocs, 
      label: "Document Management (Online)",
      meta: { url: `${process.env.VUE_APP_SERVICE_URL_ABAP_DOCS}/cxlink-documents` }
    }]
  }

  protected get otherResources(): CustomHubResource[] { 
    return [] 
  }

  protected customResourceSelected(item: CustomHubResource) {
    if (!!item.meta.url) {
      window.open(item.meta.url);
    }
  }

  protected signInForDownloads() {
    const ctx = "Sign into your account to download resources from this service.";
    this.openAuthDialog(ctx);
  }

  protected get resourceTabsDisabled(): boolean {
    return this.getResPermissions.loading || this.downloadsNotAllowed;
  }

  protected openIssueManager() {
    window.open(process.env.VUE_APP_SERVICE_URL_ISSUES);
  }

  protected get permissions() {
    return {
      CanPurchaseContracts: this.accessRole.can(ServiceID.Connector, Permission.PurchaseContract),
      CanDownloadPackages: this.accessRole.can(ServiceID.Connector, Permission.DownloadPackages),
      CanDownloadOtherPackages: this.accessRole.can(ServiceID.Connector, Permission.DownloadOtherResources)
    }
  }

  protected productVersions: ProductVersion[] = [];
  protected getProductVersions = Execution.create(ResourcesApi.getProductVersions, ResourcesApi);

  @MapAction(ResourcesStore.Mapping)
  protected fetchPlatforms: () => Promise<Platform[]>;

  protected get loading(): boolean {
    return this.getProductVersions.loading;
  }

  protected mounted()  {
    this.fetchProductVersions(ServiceID.Connector);
    
  }

  protected async fetchProductVersions(product: ServiceID) {
    const dtos = await this.getProductVersions.run(product);
    this.productVersions = dtos.map(ProductVersion.fromDto);

  }

  protected get downloadsNotAllowed(): boolean {
    return !!this.connectorResourcePermissions && !this.connectorResourcePermissions.canDownload;
  }

}
