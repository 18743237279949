import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Console, RouteConfig } from "vue-router";
import { ServiceID } from "@Service/ServiceID";
import Clone from "lodash.clonedeep";
import Vue from "vue";

export class ConsoleRoute implements Console.ServiceRouteDefinition {
  public static readonly NotFoundPath = "/cxlink/not-found";
  public static readonly ForbiddenPath = "/cxlink/forbidden";
  public static readonly ErrorPath = ["/cxlink/not-found", "/cxlink/forbidden"];
  public static readonly OrgConfigPath = "/settings/account/org-setup";
  public static readonly AuthPath = "/cxlink/auth";
  public static readonly RootPath = "/cxlink";
  public static readonly HubPath = "/cxlink/hub";
  
  public readonly path: string;
  public readonly name: string;
  public readonly meta: Console.RouteMeta;
  public readonly service: Console.ParentRouteDefinition;
  public readonly parent?: ConsoleRoute;

  public hasLegacyAccess: boolean;

  public static from(route: Console.Route): ConsoleRoute {
    const service: Console.RouteRecord = route.matched[0];
    const section: Console.RouteRecord = route.matched[route.matched.length - 1];
    return new ConsoleRoute(service, section);
  }

  public static create(service: Console.RouteRecord, section: Console.RouteRecord): ConsoleRoute {
    return new ConsoleRoute(service, section);
  }

  public get serviceId(): ServiceID | undefined {
    return this.service.meta.appInfo?.serviceId;
  }

  private constructor(service: Console.RouteRecord, section: Console.RouteRecord) {
    const routeConfigs = Vue.$router.options.routes || [];
    
    const serviceRoute = routeConfigs.find(route => route.path === service.path);
    const children: Console.RouteDefinition[] = 
      this.getChildren(serviceRoute)?.map(child => this.getDefinition(child, service.path));

    this.name = section.name;
    this.path = section.path;
    this.meta = section.meta;
    this.service = { 
      path: service.path, 
      meta: service.meta, 
      children 
    };
    
    if (!!section.parent) {
      this.parent = new ConsoleRoute(service, section.parent);
    }

    this.hasLegacyAccess = !!service.meta.appInfo?.legacy ? false : undefined;
  }

  private getChildren(route: RouteConfig): Console.RouteConfig[] | undefined {
    if (!route.children) { return; }
    const visibleChildren = Clone(route.children)
      .filter(child => child.meta.menu?.hidden !== true) as Console.RouteConfig[];
    visibleChildren.forEach(child => {
      child.children = this.getChildren(child)
    });
    return visibleChildren;
  }

  private getDefinition(config: Console.RouteConfig, servicePath: string): Console.RouteDefinition {
    const path = !config.path.startsWith(servicePath) ? `${servicePath}/${config.path}` : config.path;
    const childrenConfig = config.children as Console.RouteConfig[];
    const meta = config.meta as Console.RouteMeta;
    if (!childrenConfig) { 
      return { path, meta }; 
    } else {
      const children = childrenConfig.map(child => this.getDefinition(child, servicePath));
      return { path, meta, children };
    }
  }

  public get breadcrumb(): Console.RouteDefinition[] {
    const routes = Vue.$router.getRoutes();
    const root = routes.find(r => r.path === ConsoleRoute.RootPath) as Console.RouteRecord;
    let route = routes.find(r => r.path === this.path) as Console.RouteRecord;
    const breadcrumb = [] as Console.RouteDefinition[];
    while (!!route) {
      if (!route.meta.breadcrumb?.hidden) {
        const prependRoutes = (route.meta.breadcrumb?.prependPaths || [])
          .map(path => routes.find(r => r.path === path)) as Console.RouteRecord[];
        const visiblePrependRoutes = prependRoutes.filter(route => !route.meta.breadcrumb?.hidden);
        breadcrumb.push(...[route, ...visiblePrependRoutes]);
      }
      route = route.parent;
    }
    breadcrumb.reverse().pop();
    if (breadcrumb[0].path !== root.path) {
      return [root,...breadcrumb];
    } else {
      return breadcrumb;
    }
  }

  public getServiceSectionsForRole(role: AccessRole): Console.RouteDefinition[] {
    const filteredRoute = ConsoleRoute.filterRouteChildrenForRole(this.service, role) as Console.ParentRouteDefinition;
    return filteredRoute.children;
  }

  public static filterRouteChildrenForRole(route: Console.RouteDefinition, role: AccessRole): Console.RouteDefinition | undefined {
    if (!ConsoleRoute.isRouteAccessibleForRole(route, role)) { 
      return;
    } else if (ConsoleRoute.isParenRouteDefinition(route)) {
      const children = route.children.reduce((children, child) => {
        const filteredChild = ConsoleRoute.filterRouteChildrenForRole(child, role);
        return !!filteredChild ? [...children, filteredChild] : children;
      }, []);
      return {...route, children };
    } else {
      return route;
    }
  }

  public static isRouteAccessibleForRole(route: Console.RouteDefinition, role: AccessRole): boolean {
    if (ConsoleRoute.isParenRouteDefinition(route)) {
      const acc = route.children
        .reduce((acc, curr) => acc || ConsoleRoute.isRouteAccessibleForRole(curr, role), false);
      return acc;
    } else {
      return role.grantsAccessToRoute(route);
    }
  }

  public isAccessibleForUserWithPreviewAccess(previewAccess: ServiceID[] = []): boolean {
    const serviceId = this.service.meta.appInfo?.serviceId;
    if (!this.service.meta.appInfo?.inPreview) { 
      return true; 
    }
    return previewAccess.includes(serviceId);
  }

  public static isParenRouteDefinition(route: Console.RouteDefinition): route is Console.ParentRouteDefinition {
    return !!(route as Console.ParentRouteDefinition).children;
  }

  public get replaceNavigation(): boolean {
    const servicePreventsPush = this.service.meta.menu.replaceNavigation === true;
    const parentsPreventsPush = this.parent?.replaceNavigation === true;
    const sectionPreventsPush = this.meta.menu.replaceNavigation === true;
    return servicePreventsPush || parentsPreventsPush || sectionPreventsPush;
  }

  public get isServiceInPreview(): boolean {
    return this.service?.meta.appInfo?.inPreview === true;
  }

  public get signOutOnEnter(): boolean {
    return this.meta.signOutOnEnter;
  }

  public get isServiceLegacy(): boolean {
    return !!this.service.meta.appInfo?.legacy;
  }

  public get isHidden(): boolean {
    return this.meta.menu?.hidden === true
        || this.parent?.isHidden === true;
  }

  public get isAuthenticated(): boolean {
    return this.meta.authRequired === true;
  }

  public get isChild(): boolean {
    return !!this.parent;
  }

  public get isRoot(): boolean {
    return this.service?.path === ConsoleRoute.RootPath;
  }

  public get isAuth(): boolean {
    return this.path === ConsoleRoute.AuthPath;
  }

  public get isOrgConfig(): boolean {
    return this.path === ConsoleRoute.OrgConfigPath;
  }

  public get isError(): boolean {
    return ConsoleRoute.ErrorPath.includes(this.path);
  }

  public get redirectPath(): string {
    if (this.isServiceLegacy) { return ConsoleRoute.AuthPath; }
    const breadcrumb = [...this.breadcrumb, this].reverse();
    for (const route of breadcrumb) {
      if (route.meta.menu?.hidden !== true
        && route.meta.breadcrumb?.hidden !== true) {
        return route.path;
      }
    }
  }

}