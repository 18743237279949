import { ConnectorLicenseFilters } from "@Service/Connector/Models/Filters/ConnectorLicenseFilters.model";
import { ConnectorLicense } from "@Service/Connector/Models/ConnectorLicense.model";
import { Permission } from "@Module/Auth/Models/Roles/Permissions.model";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import { Organization } from "@Module/Orgs/Models/Organization.model";
import AuthStore from "@Module/Auth/Store/Auth.store";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import { Component, MapGetter } from "types-vue";
import { ServiceID } from "@Service/ServiceID";
import Vue from "vue";

@Component
export default class ConnectorLicenses extends Vue {
  protected serviceId: ServiceID = ServiceID.Connector;

  @MapGetter(OrgsStore.Mapping)
  protected currentOrg: Organization;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;
  
  protected filters = ConnectorLicenseFilters.create();

  protected viewDetails(license: ConnectorLicense) {
    this.$router.push({ 
      name: "abap-suite-legacy-license-details", 
      params: { id: license.id.toString() }
    });
  }

  protected viewContract(license: ConnectorLicense) {
    this.$router.push({
      name: "abap-suite-legacy-contract-details",
      params: { id: license.contractId.toString() }
    });
  }

  protected get permissions() {
    return {
      CanListContracts: this.accessRole.can(this.serviceId, Permission.ListContracts),
      CanRegisterHosts: this.accessRole.can(this.serviceId, Permission.RegisterHost),
      CanPurchaseContracts: this.accessRole.can(this.serviceId, Permission.PurchaseContract)
    }
  }

}