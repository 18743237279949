import AuthController, { AuthFormPanel } from "../AuthController/AuthController";
import { ContractPurchase } from "@Module/Auth/Models/ContractPurchase.model";
import LicensingStore from "@Module/Licensing/Store/Licensing.store";
import { AuthDialogConfig } from "@Module/Auth/Store/Auth.state";
import { Component, MapAction, MapGetter } from "types-vue";
import { ConsoleRoute } from "@Core/Models/ConsoleRoute";
import AuthStore from "@Module/Auth/Store/Auth.store";
import OrgsStore from "@Module/Orgs/Store/Orgs.store";
import Vue from "vue";

@Component
export default class AuthDialog extends Vue {

  @MapGetter(LicensingStore.Mapping)
  protected contractPurchase: ContractPurchase;

  @MapGetter(AuthStore.Mapping)
  protected authDialogVisible: boolean;

  @MapGetter(AuthStore.Mapping)
  protected authDialogConfig: AuthDialogConfig;

  @MapAction(AuthStore.Mapping)
  protected setAuthDialogVisible: (visible: boolean) => Promise<void>;

  @MapGetter(OrgsStore.Mapping)
  protected noOrganization: boolean;

  protected currentPanel: AuthFormPanel = AuthFormPanel.SignIn;
  protected shouldRender: boolean = false;
  protected busy: boolean = false;

  protected get dialogWidth(): string {
    switch (this.currentPanel) {
      case AuthFormPanel.SignIn:
        return !!this.contractPurchase ? "440px" : "405px";
      default: return "460px";
    }
  }

  protected get panel() {
    return {
      isSignIn: this.currentPanel === AuthFormPanel.SignIn,
      isSignUp: this.currentPanel === AuthFormPanel.SignUp,
      isNewPwdChallenge: this.currentPanel === AuthFormPanel.NewPwdChallenge,
      isForgotPwd: this.currentPanel === AuthFormPanel.ForgotPassword,
      isRespondInvitation: this.currentPanel === AuthFormPanel.RespondInvitation
    }
  }

  protected get authContext(): string | null {
    return this.authDialogConfig?.context || null;
  }

  protected onResolve(success: boolean) {
    this.authDialogConfig?.resolver(success);
  }

  protected onOpen() {
    this.currentPanel = AuthFormPanel.SignIn;
    this.shouldRender = true;
    this.setAuthDialogVisible(true);
  }

  protected onClose() {
    this.setAuthDialogVisible(false);
  }

  protected onClosed() {
    this.shouldRender = false;
  }

  protected closeGuard(close: VoidFunction) {
    const controller = this.$refs.authController as AuthController;
    if (!!controller) { 
      controller.closeGuard(close); 
    } else {
      close();
    }
  }

  protected onCancelForgotPwd() {
    (this.$refs.authController as AuthController).closeGuard(() => {
      this.currentPanel = AuthFormPanel.SignIn;
    });
  }

}