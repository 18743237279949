import { ContractOrigin } from "./ContractOrigin";
import { LicensingStatusPhase } from "./LicensingStatus.model";

export const enum NotificationStatus {
  Enabled = "Enabled",
  Disabled = "Disabled"
}

export class ContractFilters {
  public search: string;
  public onlyWithHosts: boolean;
  public status: LicensingStatusPhase[];
  public origins: ContractOrigin[];
  public expNotif: NotificationStatus[];

  private constructor() {
    this.search = null;
    this.status = [];
    this.origins = [];
    this.expNotif = [];
    this.onlyWithHosts = false;
  }

  public static create(): ContractFilters {
    return new ContractFilters();
  }

  public get isFiltering(): boolean {
    return this.search !== null 
        || this.onlyWithHosts
        || this.status.length > 0 
        || this.origins.length > 0
        || this.expNotif.length > 0;
  }

}