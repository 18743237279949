export enum ContractOrigin {
  Linke = "Linke",
  Trial = "Trial",
  AWS = "AWS",
  SAP = "SAP App Center",
  Syntax = "Syntax",
  MSP = "MSP",
  Extended = "Contract Extension"
}

export namespace ContractOrigin {
  export const All = [ContractOrigin.AWS, ContractOrigin.Linke, ContractOrigin.SAP, ContractOrigin.Trial, ContractOrigin.Syntax, ContractOrigin.MSP, ContractOrigin.Extended];
  export const OnlyLinke = [ContractOrigin.Linke, ContractOrigin.Extended, ContractOrigin.MSP, ContractOrigin.Syntax];

  export function nameOf(origin: ContractOrigin): string {
    switch (origin) {
      case ContractOrigin.SAP: return "SAP";
      case ContractOrigin.Linke: return "Direct Selling";
      case ContractOrigin.Syntax: return "Syntax - Internal Customer";
      default: return origin;

    }
  }
}