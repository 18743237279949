export interface ISignInChallengeDto {
  username: string;
  challenge_name: string;
  session: string;
}

export class SignInChallenge {
  public readonly username: string;
  public readonly challengeName: string;
  public readonly session: string;
  public keepSession: boolean;

  private constructor(dto: ISignInChallengeDto) {
    this.username = dto.username;
    this.challengeName = dto.challenge_name;
    this.session = dto.session;
  }

  public static fromDto(dto: ISignInChallengeDto): SignInChallenge {
    return new SignInChallenge(dto);
  }

  public get isNewPasswordChallenge(): boolean {
    return this.challengeName === "NEW_PASSWORD_REQUIRED";
  }

}