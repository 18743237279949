import { LicensingStatus, LicensingStatusPhase } from "./LicensingStatus.model";
import { LicensingNotificationType } from "./LicensingNotification.model";
import DayJS, { Dayjs } from "dayjs";
import { ContractOrigin } from "./ContractOrigin";
import { ServiceID } from "@Service/ServiceID";
import { ExpirationFilterable } from "./ExpirationFilterable.model";
import { ContractFilters, NotificationStatus } from "./ContractFilters.model";

export interface IUpdateContractDto {
  expirationDate?: string;
  expirationNotifications?: boolean;
  isPoc?: boolean;
  extended_contract_id?: number;
  origin?: string;
  product?: ServiceID;
}

export interface IContractDto {
  id: number;
  externalId: string;
  status: string;
  origin: string;
  creation: string;
  expiration: string;
  numHosts: number;
  numLicenses: number;
  expirationNotifications: boolean;
  extendedContractId: number;
  notifyExpirationAt: string;
  is_poc: boolean;
}

export type ContractNotificationsMap = Record<LicensingNotificationType, boolean>;

export class Contract implements ExpirationFilterable {
  public readonly id: number;
  public readonly externalId: string;
  public readonly origin: ContractOrigin;
  public readonly status: LicensingStatus;
  public readonly licensesCount: number;
  public readonly hostCount: number;
  public readonly creation: Dayjs;
  public readonly is_poc: boolean;
  public readonly extendedContractId: number;
  public readonly notifications: Record<LicensingNotificationType, boolean>;
  public expiration: Dayjs;

  public readonly serviceId: ServiceID;

  private constructor(dto: IContractDto, serviceId: ServiceID) {
    this.id = dto.id;
    this.externalId = dto.externalId;
    this.status = LicensingStatus.create(dto.status);
    this.origin = dto.origin as ContractOrigin;
    this.creation = DayJS(dto.creation);
    this.expiration = DayJS(dto.expiration);
    this.extendedContractId = dto.extendedContractId;
    this.licensesCount = dto.numLicenses;
    this.hostCount = dto.numHosts;
    this.is_poc = dto.is_poc;
    this.notifications = { expiration: dto.expirationNotifications }
    this.serviceId = serviceId;
  }

  public static fromDto(dto: IContractDto, serviceId: ServiceID): Contract {
    return new Contract(dto, serviceId);
  }

  public meetFilters(filters: ContractFilters): boolean {
    const text = filters.search?.toLowerCase() || "";

    if (filters.onlyWithHosts && this.hostCount === 0) {
      return false;
    }

    const meetsStatusFilter = filters.status.length > 0 ? filters.status.includes(this.status.phase) : true;
    if (!meetsStatusFilter) { return false; }

    const meetsOriginsFilter = filters.origins.length > 0 ? filters.origins.includes(this.origin) : true;
    if (!meetsOriginsFilter) { return false; }

    const meetsExpNotif = filters.expNotif.length > 0 
      ? filters.expNotif.some(notifStatus => {
        return this.hasExpirationNotifEnabled && notifStatus === NotificationStatus.Enabled
            || !this.hasExpirationNotifEnabled && notifStatus === NotificationStatus.Disabled
        }) : true;
    if (!meetsExpNotif) { return false; }
    
    if (!text) { return true; }
    const searchStatusLabel = this.status.label.toLowerCase().includes(text);
    const searchCode = this.externalId.toLowerCase().includes(text);
    const searchOrigin = this.origin.toLowerCase().includes(text);
    return searchStatusLabel || searchCode || searchOrigin;
  }

  public meetsFilter(search: string, status: LicensingStatusPhase[]): boolean {
    const text = search?.toLowerCase() || "";

    const meetsStatusFilter = status.length > 0 ? status.includes(this.status.phase) : true;
    if (!meetsStatusFilter) { return false; }
    
    if (!text) { return true; }
    const searchStatusLabel = this.status.label.toLowerCase().includes(text);
    const searchCode = this.externalId.toLowerCase().includes(text);
    const searchOrigin = this.origin.toLowerCase().includes(text);
    return searchStatusLabel || searchCode || searchOrigin;
  }

  public meetsExpirationThreshold(threshold: Dayjs): boolean {
    return !this.status.isExpired && this.expiration.isBefore(threshold);
  }

  protected get hasExpirationNotifEnabled() {
    return this.notifications.expiration && !this.status.isFinished;
  }

  protected get isAWS(): boolean {
    return this.origin === ContractOrigin.AWS;
  }

}