import { ServiceID } from "@Service/ServiceID";
import { Console } from "vue-router";
import ServiceViewport from "@Core/Components/ServiceViewport/ServiceViewport.vue";
import Viewport from "@Core/Components/Viewport/Viewport.vue";
import AbapSuiteHub from './Views/Hub/AbapSuiteHub.vue';
import AbapSuiteContracts from "./Views/Contracts/AbapSuiteContracts.vue";
import AbapSuiteLicenses from "./Views/Licenses/AbapSuiteLicenses.vue";
import AbapSuiteContractDetails from './Views/ContractDetails/AbapSuiteContractDetails.vue';
import AbapSuiteLicenseDetails from './Views/LicenseDetails/AbapSuiteLicenseDetails.vue';
import AbapSuiteDownloads from "./Views/Packages/AbapSuiteDownloads.vue";
import AbapSuiteDocumentation from "./Views/Documentation/AbapSuiteDocumentation.vue";


const routes: Console.RouteConfig[] = [
  {
    path: "/abap-suite",
    name: "abap-suite",
    component: ServiceViewport,
    redirect: { name: "abap-suite-hub" },
    meta: {
      authRequired: true,
      appInfo: {
        version: "2.0.0",
        serviceId: ServiceID.AbapSuite,
        summary: "SAP Certified add-on that leverages the power of the ABAP stack to provide a seamless integration with the hyperscalers ecosystem.",        purchaseMethods: [
          { label: "AWS Marketplace", url: process.env.VUE_APP_MARKETPLACE_URL_ABAP_SUITE },
          { label: "Contact us by email", url: `mailto:${process.env.VUE_APP_CONTACT_EMAIL}` }
        ]
      },
      menu: {
        label: ServiceID.nameOf(ServiceID.AbapSuite),
        groupId: "service",
        icon: {
          type: "custom",
          name: "icons/abap-suite.svg"
        }
      }
    },
    children: [
      {
        path: "hub",
        name: "abap-suite-hub",
        component: AbapSuiteHub,
        meta: {
          authRequired: true,
          menu: {
            label: "Service Hub",
            icon: {
              type: "ion-icon",
              name: "bookmark"
            }
          }
        }
      },
      {
        path: "licensing",
        name: "abap-suite-licensing",
        component: Viewport,
        redirect: { name: "abap-suite-contracts", append: false},
        meta: {
          authRequired: true,
          breadcrumb: {
            hidden: true
          },
          menu: {
            label: "Licensing",
            icon: {
              type: "ion-icon",
              name: "id-card"
            }
          }
        },
        children: [
          {
            path: "/abap-suite/contracts",
            name: "abap-suite-contracts",
            component: AbapSuiteContracts,
            meta: {
              authRequired: true,
              permissions: "abap-suite:ListContracts",
              menu: {
                label: "Contracts",
                icon: {
                  type: "ion-icon",
                  name: "receipt-outline"
                }
              }
            }
          },
          {
            path: "/abap-suite/contracts/:id",
            name: "abap-suite-contract-details",
            component: AbapSuiteContractDetails,
            meta: {
              authRequired: true,
              permissions: "abap-suite:ListContracts",
              breadcrumb: {
                prependPaths: ["/abap-suite/contracts"]
              },
              menu: {
                hidden: true,
                selectionPath: "/abap-suite/contracts",
                label: "Contract Details",
                icon: {
                  type: "ion-icon",
                  name: "receipt-outline"
                }
              }
            }
          },
          {
            path: "/abap-suite/licenses",
            name: "abap-suite-licenses",
            component: AbapSuiteLicenses,
            meta: {
              authRequired: true,
              permissions: "abap-suite:ListLicenses",
              menu: {
                label: "Licenses",
                icon: {
                  type: "ion-icon",
                  name: "reader-outline"
                }
              }
            }
          },
          {
            path: "/abap-suite/licenses/:id",
            name: "abap-suite-license-details",
            component: AbapSuiteLicenseDetails,
            meta: {
              authRequired: true,
              permissions: "nimbus:ListLicenses",
              breadcrumb: {
                prependPaths: ["/abap-suite/licenses"]
              },
              menu: {
                hidden: true,
                selectionPath: "/abap-suite/licenses",
                label: "License Details",
                icon: {
                  type: "ion-icon",
                  name: "reader-outline"
                }
              }
            }
          }
        ]
      },
      {
        path: "/abap-suite/resources",
        name: "abap-suite-resources",
        component: Viewport,
        redirect: { name: "abap-suite-downloads", append: false},
        meta: {
          authRequired: true,
          breadcrumb: {
            hidden: true
          },
          menu: {
            label: "Resources",
            icon: {
              type: "ion-icon",
              name: "library-outline"
            }
          }
        },
        children: [
          {
            path: "/abap-suite/donwloads",
            name: "abap-suite-downloads",
            component: AbapSuiteDownloads,
            meta: {
              authRequired: true,
              permissions: "abap-suite:DownloadPackages",
              menu: {
                label: "Downloads",
                icon: {
                  type: "ion-icon",
                  name: "archive-outline"
                }
              }
            }
          }, 
        ]
      },
    ]
  }
];

export default routes
