import AxiosAPI from "@Core/API/AxiosAPI";
import { Notify } from "@Core/Utils/Notify";
import { Utils } from "@Core/Utils/Utils";
import AuthApi from "@Module/Auth/API/Auth.api";
import NotificationsInboxStore, { NotificationsInboxStoreAction } from "@Module/Notifications/Store/NotificationsInbox.store";
import { User } from "@Module/Users/Models/User.model";
import UsersStore, { UsersStoreAction, UsersStoreGetter } from "@Module/Users/Store/Users.store";
import { Action, Getter, Module, Mutation, VuexModule } from "types-vue";
import Vue from "vue";
import { ActionContext } from "vuex";
import { SessionInitError } from "../Errors/SessionInitError";
import { AccessRole, Role } from "../Models/Roles/AccessRole.model";
import { SessionToken } from "../Models/SessionToken.model";
import { NewPwdChallenge } from "../Models/SignIn/NewPwdChallenge.model";
import { SignIn } from "../Models/SignIn/SignIn.model";
import { SignInChallenge } from "../Models/SignIn/SignInChallenge.model";
import { SignInResponse } from "../Models/SignIn/SignInResponse.model";
import { SignUp } from "../Models/SignUp/SignUp.model";
import { AuthDialogConfig, AuthStatus, AuthStoreState } from "./Auth.state";

const enum AuthStoreCookies {
  Token = "auth.token",
  AccessToken = "auth.access-token"
}

const enum AuthStoreCommit {
  SetAuthDialogVisible = "_setAuthDialogVisible",
  SetAuthDialogConfig = "_setAuthDialogConfig",
  SetSessionToken = "_setSessionToken",
  SetAuthStatus = "_setAuthStatus",
  SetSessionRestoreError = "_setSessionRestoreError",
  SetAccessRole = "_setAccessRole",
  SetRoles = "_setRoles"
}

export const enum AuthStoreAction {
  InitSession = "initSession",
  DeinitSession = "deinitSession",
  SetAccessRole = "setAccessRole",
  FetchRoles = "fetchRoles"
}

declare type AuthStoreContext = ActionContext<AuthStoreState, any>;

@Module({ namespaced: true })
export default class AuthStore extends VuexModule<AuthStoreState> implements AuthStoreState {
  public static readonly Mapping = Utils.createVuexMappingOptions("Auth");

  public _authStatus: AuthStatus = AuthStatus.SignedOut;
  public _sessionToken: SessionToken = null;
  public _sessionRestoreError: SessionInitError = null;

  public _accessRole: AccessRole = AccessRole.guestRole();
  public _roles: Role[] = [];

  public _authDialogVisible: boolean = false;
  public _authDialogConfig: AuthDialogConfig = null;

  @Getter()
  public sessionToken(): SessionToken {
    return this._sessionToken;
  }

  @Getter()
  public sessionRestoreError(): SessionInitError {
    return this._sessionRestoreError;
  }

  @Getter()
  public authStatus(): AuthStatus {
    return this._authStatus;
  }

  @Getter()
  public accessRole(): AccessRole {
    return this._accessRole;
  }

  @Getter()
  public authDialogConfig(): AuthDialogConfig {
    return this._authDialogConfig;
  }

  @Getter()
  public authDialogVisible(): boolean {
    return this._authDialogVisible;
  }

  @Getter()
  public roles(): Role[] {
    return this._roles;
  }

  @Mutation()
  protected _setRoles(roles: Role[]) {
    this._roles = roles;
  }

  @Mutation()
  protected _setAuthDialogVisible(visible: boolean) {
    this._authDialogVisible = visible;
  }

  @Mutation()
  protected _setSessionRestoreError(error: SessionInitError) {
    this._sessionRestoreError = error;
  }

  @Mutation()
  public _setAuthDialogConfig(config: AuthDialogConfig) {
    this._authDialogConfig = config;
  }

  @Mutation()
  public _setSessionToken(token: SessionToken) {
    this._sessionToken = token;
    AxiosAPI.setToken(token?.raw);
  }

  @Mutation()
  public _setAuthStatus(status: AuthStatus) {
    this._authStatus = status;
  }

  @Mutation()
  public _setAccessRole(accessRole: AccessRole) {
    this._accessRole = accessRole;
  }

  @Action({ commit: AuthStoreCommit.SetRoles })
  public async fetchRoles(): Promise<Role[]> {
    const dtos = await AuthApi.getRoles();
    return dtos.map(Role.fromDto);
  }

  @Action({ useContext: true, commit: AuthStoreCommit.SetAccessRole })
  public async setAccessRole(ctx: AuthStoreContext, accessRole?: AccessRole): Promise<AccessRole> {
    const user: User | null = UsersStore.Mapping.rootGetter(UsersStoreGetter.sessionUser)(ctx)
    return accessRole || AccessRole.guestRole(user);
  }

  @Action({ commit: AuthStoreCommit.SetAuthDialogVisible })
  public async setAuthDialogVisible(visible: boolean): Promise<boolean> {
    return visible;
  }

  @Action({ useContext: true })
  public openAuthDialog(ctx: AuthStoreContext, context?: string): Promise<boolean> {
    ctx.commit(AuthStoreCommit.SetAuthDialogVisible, true);
    return new Promise<boolean>(resolver => {
      const config: AuthDialogConfig = { context, resolver };
      ctx.commit(AuthStoreCommit.SetAuthDialogConfig, config);
    });
  }

  @Action({ useContext: true })
  public closeAuthDialog(ctx: AuthStoreContext): void {
    ctx.commit(AuthStoreCommit.SetAuthDialogVisible, false);
    ctx.commit(AuthStoreCommit.SetAuthDialogConfig, null);
  }

  @Action({ useContext: true })
  public async signUp(ctx: AuthStoreContext, model: SignUp): Promise<void> {
    try {
      ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SigningUp);
      await AuthApi.signUp(model);

      Notify.Success({ 
        title: "Successfully signed up", 
        message: `In a few minutes you will receive an email to ${model.email} in order to activate your account.`,
        duration: 6000
      });
    } finally {
      ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SignedOut);
    }
  }

  @Action({ useContext: true })
  public async signIn(ctx: AuthStoreContext, model: SignIn): Promise<SignInResponse | SignInChallenge> {
    try {
      ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SigningIn);
      const response = await AuthApi.signIn(model);

      if (response instanceof SignInResponse) {
        const sessionTime = model.keepSession ? "10d" : "0";
        Vue.$cookies.set(AuthStoreCookies.Token, response.idToken, sessionTime);
        Vue.$cookies.set(AuthStoreCookies.AccessToken, response.accessToken, sessionTime);

        const token = new SessionToken(response.idToken, response.accessToken);
        const user: User = await ctx.dispatch(AuthStoreAction.InitSession, token);

        Notify.Success({ title: "Successfully signed in", message: `Welcome ${user.fullName}`, duration: 2000 });
        ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SignedIn);
      } else {
        ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SignedOut);
      }
      return response;
    } catch (error) {
      Vue.$cookies.remove(AuthStoreCookies.Token);
      Vue.$cookies.remove(AuthStoreCookies.AccessToken);
      ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SignedOut);
      throw error;
    }
  }

  @Action({ useContext: true })
  public async respondToNewPwdChallenge(ctx: AuthStoreContext, challenge: NewPwdChallenge) {
    try {
      ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SigningIn);
      const response = await AuthApi.respondToNewPwdChallenge(challenge);

      const sessionTime = challenge.keepSession ? "10d" : "0";
      Vue.$cookies.set(AuthStoreCookies.Token, response.idToken, sessionTime);
      Vue.$cookies.set(AuthStoreCookies.AccessToken, response.accessToken, sessionTime);

      const token = new SessionToken(response.idToken, response.accessToken);
      const user: User = await ctx.dispatch(AuthStoreAction.InitSession, token);

      Notify.Success({ title: "Successfully signed in", message: `Welcome ${user.fullName}`, duration: 2000 });
      ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SignedIn);
    } catch (error) {
      ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SignedOut);
      throw error;
    }
  }

  @Action({ useContext: true })
  public async signOut(ctx: AuthStoreContext, expired: boolean = false): Promise<void> {
    ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SigningOut);
    const token = ctx.state._sessionToken;
    if (!expired && !!token && !!token.accessToken && !token.hasExpired) {
      await AuthApi.signOut(token.accessToken).catch(e => {
        console.error(e);
        return e;
      });
      //$router.replace("/cxlink/auth").catch(e => e);
    }
    await ctx.dispatch(AuthStoreAction.DeinitSession, expired);
    ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SignedOut);
  }

  @Action({ useContext: true })
  public async loadSessionFromCookies(ctx: AuthStoreContext): Promise<void> {
    const idToken = Vue.$cookies.get(AuthStoreCookies.Token);
    if (!idToken) { return; }

    ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.RestoringSession);
    const accessToken = Vue.$cookies.get(AuthStoreCookies.AccessToken);
    const token = new SessionToken(idToken, accessToken);
    if (!token.hasExpired) {
      try {
        await ctx.dispatch(AuthStoreAction.InitSession, token);
        ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SignedIn);
      } catch (error) {
        ctx.commit(AuthStoreCommit.SetSessionRestoreError, error);
        ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SignedOut);
      }
    } else {
      Vue.$cookies.remove(AuthStoreCookies.Token);
      Vue.$cookies.remove(AuthStoreCookies.AccessToken);
      ctx.commit(AuthStoreCommit.SetAuthStatus, AuthStatus.SignedOut);
    }
  }

  @Action({ useContext: true })
  public async initSession(ctx: AuthStoreContext, token: SessionToken): Promise<User> {
    ctx.commit(AuthStoreCommit.SetSessionRestoreError, null);
    try {
      AxiosAPI.setToken(token.raw);

      // Initialize session user
      const initSessionUser = UsersStore.Mapping.rootAction(UsersStoreAction.InitSessionUser);
      const sessionUser = await initSessionUser(ctx, token.username);

      const fetchRoles = ctx.dispatch(AuthStoreAction.FetchRoles);
      const fetchNotifInbox = NotificationsInboxStore.Mapping.rootAction(NotificationsInboxStoreAction.FetchInbox)(ctx);
      await Promise.all([fetchRoles, fetchNotifInbox]);

      ctx.commit(AuthStoreCommit.SetSessionToken, token);
      return sessionUser;
    } catch (error) {
      console.error("[!] Session initialization error - ", error.message, error.cause, error);
      AxiosAPI.setToken(null);
      const message = "An error occurred while initializing your session. If the problem persists, please sign in again.";
      throw new SessionInitError(message, {
        cause: error,
        fatal: true,
        meta: { time: new Date() }
      });
    }
  }

  @Action({ useContext: true })
  public async deinitSession(ctx: AuthStoreContext, expired: boolean): Promise<void> {
    // Deinitialize session user
    const deinitSessionUser = UsersStore.Mapping.rootAction(UsersStoreAction.DeinitSessionUser);
    await deinitSessionUser(ctx, expired);

    Vue.$cookies.keys()
      .filter(name => name.startsWith("auth"))
      .forEach(name => Vue.$cookies.remove(name));

    ctx.commit(AuthStoreCommit.SetRoles, []);
    ctx.commit(AuthStoreCommit.SetAccessRole, AccessRole.guestRole());
    ctx.commit(AuthStoreCommit.SetSessionToken, null);
  }

}