import { ContractPurchaseNotification } from "@Module/Notifications/Models/ContractPurchaseNotification.model";
import { OrgInvitationNotificationLink } from "@Module/Notifications/Models/OrgInvitationNotification.model";
import NotificationsOutboxStore from "@Module/Notifications/Store/NotificationsOutbox.store";
import NotificationsInboxStore from "@Module/Notifications/Store/NotificationsInbox.store";
import SessionInterceptorMixin from "../Modules/Auth/Mixins/SessionInterceptor.mixin";
import AppSettingsStore from "@Service/Settings/Store/AppSettings/AppSettings.store";
import { UISize } from "@Service/Settings/Store/AppSettings/AppSettings.state";
import { ContractPurchase } from "@Module/Auth/Models/ContractPurchase.model";
import { Component, MapAction, MapGetter, mixins, Watch } from "types-vue";
import { AccessRole } from "@Module/Auth/Models/Roles/AccessRole.model";
import StateCleanerMixin from "@Module/Auth/Mixins/StateCleaner.mixin";
import { SetRoutePayload } from "./Store/Navigation/Navigation.state";
import LicensingStore from "@Module/Licensing/Store/Licensing.store";
import NavigationStore from "./Store/Navigation/Navigation.store";
import { AuthStatus } from "@Module/Auth/Store/Auth.state";
import { Console, NavigationGuardNext } from "vue-router";
import UsersStore from "@Module/Users/Store/Users.store";
import { User } from "@Module/Users/Models/User.model";
import { ConsoleRoute } from "./Models/ConsoleRoute";
import AuthStore from "@Module/Auth/Store/Auth.store";
import MenuStore from "./Store/Menu/Menu.store";
import { Notify } from "./Utils/Notify";
import { Utils } from "./Utils/Utils";
import Vue from "vue";

@Component
export default class App extends mixins(SessionInterceptorMixin, StateCleanerMixin) {

  @MapGetter(AppSettingsStore.Mapping)
  protected uiSize: UISize;

  @MapGetter(UsersStore.Mapping)
  protected sessionUser: User;

  @MapGetter(AuthStore.Mapping)
  protected accessRole: AccessRole;

  @MapGetter(AuthStore.Mapping)
  protected authStatus: AuthStatus;

  @MapGetter(LicensingStore.Mapping)
  protected contractPurchase: ContractPurchase;

  @MapGetter(NavigationStore.Mapping)
  protected navigationInitialized: boolean;

  @MapGetter(NavigationStore.Mapping)
  protected getRoute: (path: string) => ConsoleRoute;

  @MapAction(AppSettingsStore.Mapping)
  private loadSettingsFromCookies: () => Promise<void>;

  @MapAction(AuthStore.Mapping)
  private loadSessionFromCookies: () => Promise<void>;

  @MapAction(NavigationStore.Mapping)
  protected initNavigation: () => Promise<void>;

  @MapAction(NavigationStore.Mapping)
  protected setRoute: (route: SetRoutePayload) => Promise<ConsoleRoute>;

  @MapAction(MenuStore.Mapping)
  protected setFloatingMenuVisible: (visible: boolean) => Promise<void>;

  @MapAction(NotificationsInboxStore.Mapping)
  protected setOrgInvitationLink: (link: OrgInvitationNotificationLink) => Promise<OrgInvitationNotificationLink>;

  @MapAction(NotificationsOutboxStore.Mapping)
  protected sendContractPurchase: (contractPurchase: ContractPurchase) => Promise<ContractPurchaseNotification>;

  @MapAction(LicensingStore.Mapping)
  protected setContractPurchase: (purchase: ContractPurchase) => Promise<ContractPurchase>;

  @Watch("$route", { immediate: true })
  protected async onRouteChange(to: Console.Route, from?: Console.Route) {
    if (to.matched.length === 0) { return; }

    if (!this.navigationInitialized) { await this.initNavigation(); }
    this.setFloatingMenuVisible(false);

    const route = await this.setRoute({ current: to, previous: from });
    if (route.service.meta.menu.label === "CxLink") {
      window.document.title = "CxLink";
    } else {
      window.document.title = `CxLink - ${route.service.meta.menu.label}`;
    }

    if (route.signOutOnEnter && !from) {
      await this.performSignOut();
    }

    await this.loadUrlQueryParams(to.query);
  }

  @Watch("authStatus", { immediate: true })
  @Watch("contractPurchase", { immediate: true })
  protected async onDestinationRouteChange() {
    if (this.authStatus === AuthStatus.SignedIn && !!this.contractPurchase) {

      if (this.contractPurchase.isError || this.contractPurchase.isUpdate) {
        await this.setContractPurchase(null);
        return;
      }

      await this.$nextTick();
      const loadingNotif = Notify.Indeterminate({ 
        title: "Registering contract purchase", 
        message: "Please wait while your contract purchase is being registered.",
      });

      try {
        await this.sendContractPurchase(this.contractPurchase);
        await Utils.sleep(800);
        loadingNotif.close();
        await this.$nextTick();
        Notify.Success({
          title: "Contract purchase registered",
          message: `Your contract purchase has been successfully registered. You will receive a notification in your inbox to complete the purchase process.`,
          duration: 10000
        });
      } catch (error) {
        await Utils.sleep(1000);
        loadingNotif.close();
        await this.$nextTick();
        Notify.Error({
          title: "Purchase registration error",
          message: `An error occurred registering your contract purchase. Please relaunch the purchase process again.`,
          duration: 10000
        });
      }
      
      await this.setContractPurchase(null);
    }
  }

  protected async loadUrlQueryParams(query: Record<string, string | string[]>) {
    query = Object.assign({}, query);
    const hasContractPurchase = ContractPurchase.hasContractPurchase(query);
    const hasOrgInvitationLink = OrgInvitationNotificationLink.hasOrgInvitationLink(query);
    if (!hasContractPurchase && !hasOrgInvitationLink) { 
      return; 
    }
    
    const contractPurchase = ContractPurchase.fromQuery(query);
    if (!!contractPurchase) {
      await this.setContractPurchase(contractPurchase);
      query = ContractPurchase.clearQuery(query);
    }

    const orgInvitation = OrgInvitationNotificationLink.fromQuery(query);
    if (!!orgInvitation) {
      await this.setOrgInvitationLink(orgInvitation);
      query = OrgInvitationNotificationLink.clearQuery(query);
    }

    this.$router.replace({ query: query }).catch(e => e);
  }

  @Watch("uiSize", { immediate: true })
  protected onUiSizeChange(newSize: UISize, oldSize: UISize) {
    if (!!oldSize) {
      document.body.classList.remove(`ui-size-${oldSize}`);
    }
    document.body.classList.add(`ui-size-${newSize}`);
  }

  protected async created() {
    this.$Progress.start();
    await this.initNavigation();
    Vue.$cookies.config("30d");
    await this.loadSettingsFromCookies();
    await this.loadSessionFromCookies();
    this.$router.beforeEach(this.routeGuard);
    this.$Progress.finish();
  }

  private async routeGuard(to: Console.Route, from: Console.Route, next: NavigationGuardNext<Vue>) {
    if (to.matched.length === 0) { 
      return next({ 
        path: ConsoleRoute.NotFoundPath, 
        replace: true 
      });
    }

    const route = to.matched[to.matched.length - 1];
    const targetRoute = this.getRoute(route.path);
    if (targetRoute.isServiceLegacy && !targetRoute.hasLegacyAccess) {
      return next({ 
        path: ConsoleRoute.NotFoundPath,
        replace: true 
      });
    } else if (!!this.sessionUser && !this.accessRole.grantsAccessToRoute(targetRoute)) {
      return next({ 
        path: ConsoleRoute.ForbiddenPath,
        replace: true
      });
    } else {
      if (targetRoute.signOutOnEnter && to.path !== from?.path) {
        await this.performSignOut();
      }
      return next();
    }
  }
  
}