export enum ServiceID {
  Console = "console",
  Emory = "emory",
  Connector = "nimbus",
  AbapSuite = "abap-suite",
  Amis = "amis",
}

export namespace ServiceID {
  
  export const All: ServiceID[] = [ServiceID.Emory, ServiceID.Connector, ServiceID.Amis, ServiceID.AbapSuite];
  export const WithLicensing: ServiceID[] = [ServiceID.Emory, ServiceID.Connector, ServiceID.AbapSuite];
  export const ForReports: ServiceID[] = [ServiceID.Emory, ServiceID.AbapSuite];

  export function nameOf(service: ServiceID) {
    switch (service) {
      case ServiceID.Emory: return "Backup";
      case ServiceID.Connector: return "Suite (Legacy)";
      case ServiceID.AbapSuite: return "Suite";
      case ServiceID.Amis: return "Pre-installed SAP";
    }
  }
}

export interface ServicePayload<T> {
  readonly service: ServiceID;
  readonly data: T;
}

export interface OptionalServicePayload<T> {
  readonly service: ServiceID;
  readonly data?: T;
}

export type ServiceMap<T> = {
  [serviceId in ServiceID]?: T;
}

